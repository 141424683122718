import ReactWebSocketService from "../../service/ReactWebSocketService";
import {
  updateCurrentListItemDispatcher,
  EntityType,
  Operation,
  currentEntityAction,
  updateCurrentEntityChildItemAction,
} from "./CurrentActions";
import {
  showMessageBarAction,
  addToasterMessageAction,
  removeToasterMessageAction,
} from "./UIActions";
import { handleNetworkReconnectionDispatcher } from "./LoginActions";
import {
  updateFileUploadContentAction,
  updateUploadAction,
} from "./UploadQueueActions";
import {
  addSecondsToDate,
  getWebsocketUrl,
  isWWW,
  randomString,
} from "../../util/Utils";
import { markChatConversationReadDispatcher } from "./UpdateEntityActions";
import { logError, logWarning } from "../../service/ServiceUtil";

let keepAliveWorker;
let reactWebSocketService;

export const socketConnectionDispatcher = (sessionKey, accountId) => {
  return (dispatch, getState) => {
    dispatch(socketDisconnectionDispatcher());
    reactWebSocketService = new ReactWebSocketService(
      getWebsocketUrl(`/api/v1/socket/entitySocket/${sessionKey}`)
    );
    reactWebSocketService.onOpen = () => {
      dispatch(accountIdAction(accountId));
      dispatch(handleNetworkReconnectionDispatcher());
      dispatch(keepAliveAction(290));
    };
    reactWebSocketService.onMessage = (data) => {
      const message = JSON.parse(data);
      const entity = message.object;
      const entityType = message.entityType;
      const entityTypeName = EntityType[entityType];
      const operation = message.operation;
      if (entityTypeName === EntityType.Upload) {
        dispatch(updateUploadAction(entity));
      }

      const currentIdState = getState().current.id;
      const ui = getState().ui;

      const idFieldName = `${entityTypeName}Id`;
      if (
        typeof currentIdState[idFieldName] !== "undefined" &&
        currentIdState[idFieldName] === entity[idFieldName]
      ) {
        if (operation === Operation.Delete) {
          entity.name &&
            dispatch(showMessageBarAction(true, `${entity.name} deleted`));
          dispatch(currentEntityAction(entityTypeName, null));
        } else {
          dispatch(currentEntityAction(entityTypeName, entity));
        }
      }
      if (operation === Operation.Create || operation === Operation.Update) {
        dispatch(updateCurrentEntityChildItemAction(entityTypeName, entity));
      }
      dispatch(
        updateCurrentListItemDispatcher(entityTypeName, entity, operation)
      );
      //If its content update the fileUpload. this is required because fileUploadArray is not stored in the currentList):
      if (EntityType[entityType] === EntityType.Content) {
        dispatch(updateFileUploadContentAction(entity));
      }
      //If this is a chat message that should be marked read, dispatch markChatMessagesRead..
      if (
        entityTypeName === EntityType.ChatMessage &&
        currentIdState.chatConversationId === entity.chatConversationId &&
        window.location.pathname.startsWith("/chat")
      )
        dispatch(markChatConversationReadDispatcher(entity.chatConversationId));
      //If this us an unread NotificationMessage, display an Alert unless the users are already chatting:
      if (
        entityTypeName === EntityType.NotificationMessage &&
        !(
          EntityType[entity.entityType] === EntityType.ChatMessage &&
          currentIdState.chatConversationId === entity.chatConversationId &&
          window.location.pathname.startsWith("/chat")
        )
      ) {
        if (!entity.isRead) dispatch(addToasterMessageAction(entity));
        else dispatch(removeToasterMessageAction(entity.id));
      }
      if (
        entityTypeName === EntityType.UserSession &&
        entity.javaScriptCommand
      ) {
        try {
          switch (entity.javaScriptCommand.command) {
            default:
              logError(
                "UserSession javaScriptCommand invalid command",
                entity.javaScriptCommand
              );
          }
        } catch (e) {
          logError(
            "Error processing UserSession javaScriptCommand.",
            entity.javaScriptCommand
          );
        }
      }
    };
  };
};

export const accountIdAction = (accountId) => {
  return (dispatch, getState) => {
    doSocketRequest("setAccountId", { accountId });
  };
};

let keepAliveId;
let nextKeepAliveTime;
export const keepAliveAction = (seconds) => {
  setupKeepAliveWorker();
  return () => {
    nextKeepAliveTime = addSecondsToDate(new Date(), seconds);
    keepAliveId = `entitySocket ${randomString(8)}`;
    checkKeepAlive(keepAliveId, seconds);
  };
};

const checkKeepAlive = (checkKeepAliveId, seconds) => {
  if (keepAliveId !== checkKeepAliveId) return;
  try {
    if (!reactWebSocketService.isConnected) {
      if (keepAliveId) {
        keepAliveId = false;
        return;
      }
    } else if (new Date().getTime() >= nextKeepAliveTime.getTime()) {
      nextKeepAliveTime = addSecondsToDate(new Date(), seconds);
      doSocketRequest("keepAlive", {});
    }
    keepAliveWorker.postMessage({
      messageType: "requestAnimationFrame",
      timeout: 2000,
      requester: checkKeepAliveId,
      recorderOptions: { seconds },
    });
  } catch (e) {
    logWarning("keepAliveAction failed.", e);
    keepAliveId = false;
  }
};

const setupKeepAliveWorker = () => {
  if (!keepAliveWorker && typeof Worker !== "undefined" && !isWWW) {
    keepAliveWorker = new Worker("/static/js/RecorderWorker.js");
    keepAliveWorker.addEventListener("message", (event) => {
      const {
        messageType = "",
        recorderOptions = {},
        requester,
      } = event.data ?? {};
      switch (messageType) {
        case "recorderAnimationStep":
          checkKeepAlive(requester, recorderOptions.seconds);
          break;
      }
    });
  }
};

const doSocketRequest = (socketRequestAction, data) => {
  const request = { socketRequestAction, data };
  if (typeof reactWebSocketService !== "undefined")
    reactWebSocketService.send(request);
};

export const socketDisconnectionDispatcher = () => {
  return (dispatch, getState) => {
    if (typeof reactWebSocketService !== "undefined")
      reactWebSocketService.close();
  };
};
